.card-hover:hover .btn-hidden {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
	border-radius: 10px;
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, visibility 0.2s ease-in-out,
		background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out;
}

.btn-hidden {
	visibility: hidden;
	opacity: 0;
	transform: translateY(5px);
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, visibility 0.2s ease-in-out,
		background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out;
}

.card-hover:hover .btn-hidden:hover {
	background-color: rgb(0, 41, 54);
	border-radius: 50px;
}

.card-title {
	font-size: 2.3rem;
}

.card {
	z-index: 0;
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}
